<template>
  <div v-for="coach in coaches" :key="coach.id" class="coach flex center">
    <div class="text">
      <h1>{{ coach.name }} {{ coach.surname }}</h1>
      <h3>{{ coach.role }}</h3>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem inventore facilis placeat
        sunt quia adipisci dolores, omnis, dignissimos quisquam expedita tenetur enim in. Tempore
        dignissimos totam ipsum nisi asperiores placeat. Lorem ipsum dolor sit amet consectetur
        adipisicing elit. Porro facilis, quibusdam rerum reiciendis magnam consequuntur consequatur
        eligendi fuga libero doloremque quos, est explicabo dolore minima itaque fugiat aliquid
        quasi tenetur.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem inventore facilis placeat
        sunt quia adipisci dolores, omnis, dignissimos quisquam expedita tenetur enim in. Tempore
        dignissimos totam ipsum nisi asperiores placeat.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorem inventore facilis placeat
        sunt quia adipisci dolores, omnis, dignissimos quisquam expedita tenetur enim in. Tempore
        dignissimos totam ipsum nisi asperiores placeat.
      </p>
    </div>
    <img :src="imagesUrl + coach.id + '/' + coach.photo" />
  </div>
</template>

<script lang="ts">
import { Coach } from '@/models/coach.model';
import { getCoaches } from '@/services/coaches_service';
import { BASE_URL, constructQuery } from '@/services/helpers';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CoachesView',
  data() {
    return {
      query: constructQuery(),
      imagesUrl: `${BASE_URL}files/coaches/`,
      coaches: [] as Array<Coach>,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    async fetchData() {
      try {
        const response = await getCoaches(this.query);
        this.coaches = response.items;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
  },
});
</script>

<style scoped>
.coach:nth-of-type(2n) {
  background-color: var(--primary);
  flex-direction: row-reverse;

  h1,
  h3,
  p {
    color: var(--white);
  }
}

.coach {
  gap: 180px;
  padding: 2em;

  .text {
    h1 {
      text-transform: none;
      max-width: 600px;
    }
  }

  @media (max-width: 1439px) {
    flex-wrap: inherit;
    gap: 0px;
    justify-content: space-between;

    .text {
      p {
        max-width: 500px;
      }
    }
  }

  @media (max-width: 1070px) {
    gap: 0px;
    justify-content: space-between;

    img {
      max-width: 400px;
    }

    .text {
      h1 {
        max-width: 400px;
        font-size: 50px;
      }

      h3 {
        margin-bottom: 10px;
      }

      p {
        max-width: 400px;
      }
    }
  }

  @media (max-width: 860px) {
    gap: 0px;
    justify-content: flex-start;
    flex-direction: column;
    padding: 1em;

    &:not(.blue) {
      flex-direction: column-reverse;
    }

    img {
      max-width: none;
      width: 100%;
      height: 500px;
      margin-bottom: 15px;
    }

    .text {
      max-width: unset;
      width: 100%;

      p {
        max-width: unset;
      }

      h1 {
        font-size: 50px;
      }

      h3 {
        margin-bottom: 25px;
      }
    }
  }
}

:is(.coach) > img {
  width: 500px;
  height: 600px;
  object-fit: cover;
  object-position: top;
  border-radius: 0px;
}

.text {
  h1 {
    margin-left: -5px;
    font-size: 70px;
  }

  h3 {
    margin-bottom: 50px;
    text-transform: uppercase;
    font-weight: 500;
  }

  p {
    max-width: 628px;
    text-align: justify;
  }
}
</style>
