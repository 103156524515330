import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';
import AboutView from '../views/AboutView.vue';
import TeamsView from '../views/TeamsView.vue';
import CoachesView from '../views/CoachesView.vue';
import NewsView from '../views/NewsView.vue';
import TeamDetailsView from '../views/TeamDetails.vue';
import NewsDetails from '@/views/NewsDetails.vue';
import NotFoundView from '@/views/NotFoundView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/onama',
    name: 'onama',
    component: AboutView,
  },
  {
    path: '/timovi',
    name: 'timovi',
    component: TeamsView,
  },
  {
    path: '/treneri',
    name: 'treneri',
    component: CoachesView,
  },
  {
    path: '/novosti',
    name: 'novosti',
    component: NewsView,
  },
  {
    path: '/timovi/:id',
    name: 'tim',
    component: TeamDetailsView,
  },
  {
    path: '/novosti/:id',
    name: 'novost',
    component: NewsDetails,
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: NotFoundView,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
