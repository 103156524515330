<template>
  <Container
    title="TIMOVI"
    color="var(--primary)"
    text-color="var(--white)"
    border-color="var(--secondary)"
    style="min-height: 60vh"
  >
    <div class="team grid">
      <div
        class="flex column center"
        v-for="(team, index) in teams"
        :key="index"
        @click="navigateToTeam(team.id)"
      >
        <img :src="imagesUrl + team.id + '/' + team.photo" />
        <h4>{{ team.name }}</h4>
      </div>
    </div>
  </Container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Container from '@/components/Container.vue';
import { Team } from '@/models/team.model';
import { BASE_URL, constructQuery } from '@/services/helpers';
import { getTeams } from '@/services/teams_service';

export default defineComponent({
  name: 'TeamsView',
  components: {
    Container,
  },
  data() {
    return {
      query: constructQuery(undefined, undefined, 'order'),
      imagesUrl: `${BASE_URL}files/teams/`,
      teams: [] as Array<Team>,
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await getTeams(this.query);
        this.teams = response.items;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    navigateToTeam(name: string) {
      this.$router.push('/timovi/' + name);
    },
  },

  mounted() {
    const html = document.getElementsByTagName('html')[0];
    html.style.backgroundColor = '#104d8c';
    this.fetchData();
  },
  beforeUnmount() {
    const html = document.getElementsByTagName('html')[0];
    html.style.backgroundColor = '';
  },
});
</script>

<style scoped>
.grid {
  display: grid;
  justify-content: center;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width: 1300px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
}
.team {
  gap: 25px;

  h4 {
    color: white;
  }

  img {
    width: 370px;
    height: 250px;
    object-fit: cover;

    @media (max-width: 600px) {
      width: 100%;
      height: 200px;
    }
  }
}

.grid > div {
  transition: all 0.4s;
  cursor: pointer;

  &:hover {
    transform: scale(1.05);
  }
}
</style>
