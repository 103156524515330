<template>
  <div class="banner">
    <img class="banner-image" src="img/banner.jpg" alt="" />

    <Carousel :items-to-show="1" :wrapAround="true" :autoplay="5000" :touch-drag="true">
      <Slide v-for="item in news" :key="item.id" @click="navigateToNews(item.id)">
        <Container
          :title="item.title"
          color="none"
          text-color="var(--white)"
          border-color="var(--secondary)"
        >
          <p style="color: white; text-align: justify">
            {{ item.summary }}
          </p>
          <small style="color: white">{{ formatDate(item.date) }}</small>
        </Container>
      </Slide>
      <template #addons>
        <Navigation />
        <Pagination />
      </template>
    </Carousel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import 'vue3-carousel/dist/carousel.css';
import Container from './Container.vue';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { News } from '@/models/news.model';
import { getNews } from '@/services/news_service';
import { constructQuery, formatDate } from '@/services/helpers';

export default defineComponent({
  name: 'Banner',
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    Container,
  },
  data() {
    return {
      query: constructQuery(1, 5, '-date'),
      news: [] as Array<News>,
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await getNews(this.query);
        this.news = response.items;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    formatDate,
    navigateToNews(id: string) {
      this.$router.push('/novosti/' + id);
    },
  },
  mounted() {
    this.fetchData();
  },
});
</script>

<style>
.carousel,
.carousel__track {
  width: 800px;
  height: 400px;
  cursor: pointer;
}

.carousel__slide {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border-radius: 4px;
}

.carousel__prev,
.carousel__next {
  background: var(--primary) !important;
  width: 60px;
  height: 40px;
  cursor: pointer;
  border-radius: 4px;
}

.carousel__prev:hover,
.carousel__next:hover {
  background: var(--secondary) !important;
}

.carousel__prev {
  margin-left: -80px !important;
}

.carousel__next {
  margin-right: -80px !important;
}

.carousel__pagination {
  padding: 0;
}

.carousel__icon {
  fill: white !important;
  font-size: 30px;
}

@media (max-width: 730px) {
  .carousel,
  .carousel__track {
    width: 100%;
    height: fit-content;
  }

  .banner {
    background-image: url('/public/img/banner.jpg');
    background-size: cover;
    background-position: center bottom;
  }

  .carousel__prev,
  .carousel__next {
    display: none;
  }

  .carousel__slide {
    border-radius: 0px;
  }
}
</style>

<style scoped>
.banner {
  padding: 0px 10%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0rem;
}

.banner-image {
  position: absolute;
  top: -400px;
  width: 100%;
  z-index: -100;
  max-height: 1500px;
  filter: blur(1px);

  @media (max-width: 700px) {
    display: none;
  }

  @media (max-width: 1700px) {
    top: 0px;
  }
}
</style>
