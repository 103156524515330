<template>
  <section v-if="newsDetails" class="news-details flex column">
    <section class="header flex">
      <img :src="imagesUrl + newsDetails.id + '/' + newsDetails.lead_photo" />
      <div class="title">
        <small>{{ formatDate(newsDetails.date) }}</small>
        <h1>{{ newsDetails.title }}</h1>
      </div>
    </section>
    <section class="flex content space-btwn">
      <div>
        <div class="news-paragraphs" v-html="content[0]"></div>
        <Gallery
          v-if="newsDetails.photos && newsDetails.photos.length > 0"
          :images="newsDetails.photos"
          :news-id="newsDetails.id"
        ></Gallery>
        <div class="news-paragraphs" v-html="content[1]"></div>
      </div>

      <MostRecent></MostRecent>
    </section>
  </section>
</template>

<script lang="ts">
import MostRecent from '@/components/MostRecent.vue';
import { defineComponent } from 'vue';
import { News } from '@/models/news.model';
import { BASE_URL, formatDate } from '@/services/helpers';
import { getNewsById } from '@/services/news_service';
import Gallery from '@/components/Gallery.vue';

export default defineComponent({
  name: 'NewsDetails',
  components: {
    MostRecent,
    Gallery,
  },
  data(): { newsDetails: News | null; imagesUrl: string; content: Array<string> } {
    return {
      newsDetails: null,
      imagesUrl: `${BASE_URL}files/news/`,
      content: [],
    };
  },
  methods: {
    async fetchData() {
      try {
        const id = this.$route.params.id as string;
        this.newsDetails = await getNewsById(id);
        this.content = this.newsDetails.content.split('<p>GALLERY</p>');
      } catch (error) {
        this.$router.push('/404');
      }
    },
    formatDate,
  },
  mounted() {
    this.fetchData();
  },
  watch: {
    '$route.params.id': {
      immediate: true,
      handler() {
        this.fetchData();
      },
    },
  },
});
</script>

<style scoped>
.news-details {
  min-height: 60vh;
  gap: 50px;
  padding: 60px 5rem;
  flex-wrap: nowrap;

  @media (max-width: 700px) {
    padding: 1em;
  }
}

small {
  color: #606470;

  @media (prefers-color-scheme: dark) {
    color: #b7b8ba;
  }

  display: block;
  margin-bottom: 1em;
}

.header {
  max-width: 1300px;
  width: 100%;
  flex-wrap: nowrap;
  gap: 3rem;
  padding: 2rem;
  border-radius: 7px;

  background-color: #f1f5f9;

  @media (prefers-color-scheme: dark) {
    background-color: var(--mirage);
  }

  img {
    width: 40%;
    aspect-ratio: 1.2;
    object-fit: cover;
    object-position: center;
  }

  @media (max-width: 1000px) {
    flex-direction: column-reverse;

    img {
      width: 100%;
    }
  }

  @media (max-width: 701px) {
    padding: 1rem;
  }
}

.content {
  width: 100%;
  max-width: 1300px;
  gap: 50px;
  padding: 0 2rem;
  align-items: flex-start;

  @media (max-width: 1335px) {
    flex-direction: column;
  }

  @media (max-width: 701px) {
    padding: 0;
    gap: 0;
  }
}

h1 {
  font-size: 45px;
  max-width: 900px;

  @media (max-width: 420px) {
    font-size: 35px;
  }
}
</style>

<style>
.news-paragraphs {
  max-width: 776px;

  p {
    font-size: 20px;
    margin-bottom: 30px;
  }

  p:first-child {
    margin-top: 0px;
  }
}
</style>
