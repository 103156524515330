<template>
  <footer class="flex column" v-if="!$route.meta.hideNavbar">
    <div class="flex container space-btwn">
      <div class="block">
        <span class="title">Poveznice</span>
        <div class="items">
          <router-link to="/">
            <span>Početna</span>
          </router-link>
          <router-link to="/novosti">
            <span>Novosti</span>
          </router-link>
          <router-link to="/timovi">
            <span>Timovi</span>
          </router-link>
          <router-link to="/treneri">
            <span>Treneri</span>
          </router-link>
          <router-link to="/onama">
            <span>O nama</span>
          </router-link>
        </div>
      </div>

      <div class="block">
        <span class="title">Podrška</span>
        <div class="items">
          <span>Uvjeti korištenja</span>
          <span>Izjava o pristupačnosti</span>
          <span>Izvještaji o transparentnosti</span>
        </div>
      </div>

      <div class="block">
        <span class="title">Kontakt</span>
        <div class="items">
          <span>
            <a href="tel:+385 95 9681735"> Telefon: 095-9681-735 </a>
          </span>
          <span>
            <a href="tel:+385 91 4455737"> Telefon: 091-4455-737 </a>
          </span>
          <span>
            <a href="info@mnkfutsalskolazagi.hr"> E-mail: info@mnkfutsalskolazagi.hr </a>
          </span>
        </div>
      </div>
    </div>

    <div class="copyright">
      <img src="/img/zagi-logo.svg" alt="" />
      ©2024. FUTSAL ZAGI
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Footer',
  components: {},
});
</script>

<style scoped>
footer {
  background-color: var(--dark);
}
.container {
  align-items: baseline;
  gap: 130px;
  padding: 30px 0px;
}

@media (max-width: 730px) {
  footer {
    align-items: baseline;
    padding-left: 30px;
  }
  .container {
    flex-direction: column;
    gap: 30px;
  }
}

.items {
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 13px;
}

.block {
  display: flex;
  flex-direction: column;
  gap: 30px;

  span.title {
    color: var(--white);
    font-size: 21px;
    font-weight: bold;
    text-transform: uppercase;
  }

  span {
    color: #989898;
  }

  a {
    text-decoration: none;
    color: #989898;

    span:hover,
    &:hover {
      color: var(--primary);
    }
  }
}

.copyright {
  color: #989898;
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;

  img {
    width: 100px;
  }
}
</style>
