<template>
  <div class="news-wrapper">
    <div class="news-card flex">
      <img :src="image" v-if="!image.endsWith('/')" alt="" />
      <div class="no-image flex column center" v-if="image.endsWith('/')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-camera-off"
        >
          <line x1="2" x2="22" y1="2" y2="22" />
          <path d="M7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16" />
          <path d="M9.5 4h5L17 7h3a2 2 0 0 1 2 2v7.5" />
          <path d="M14.121 15.121A3 3 0 1 1 9.88 10.88" />
        </svg>
      </div>
      <div class="flex column text space-btwn">
        <div class="summary">
          <h4>{{ title }}</h4>
          <p class="content">{{ summary }}</p>
          <span class="link" @click="goToDetails()">Pročitajte više »</span>
        </div>
        <span class="date">{{ formatDate(date) }}</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { formatDate } from '@/services/helpers';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NewsCard',
  methods: {
    formatDate,
    goToDetails() {
      this.$router.push(this.link);
    },
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    summary: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
});
</script>

<style scoped>
.news-wrapper {
  max-width: 1160px;
  width: 100%;
}
.news-card {
  width: 1160px;
  padding-bottom: 30px;
  gap: 20px;
  flex-wrap: nowrap;
  gap: 2rem;
  border-bottom: 1px solid #dbdee5;

  @media (prefers-color-scheme: dark) {
    border-bottom: 1px solid #72757c;
  }

  @media (max-width: 1200px) {
    width: auto;
    padding-left: 30px;
    padding-right: 30px;
    border: none;
  }

  @media (max-width: 900px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
  }

  @media (max-width: 490px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

.text {
  align-items: start;
  height: 250px;
  width: 100%;

  @media (max-width: 900px) {
    height: auto;
  }
}

.content {
  margin: 0px 0px 30px 0px;
  text-align: justify;
}

:is(.news-card) > img {
  width: 400px;
  height: 250px;
  object-fit: cover;
  object-position: center;
  flex-shrink: 0;

  @media (max-width: 490px) {
    width: 100%;
    height: 200px;
  }
}

.no-image {
  background-color: #a3a3a3;
  width: 400px;
  height: 250px;
  flex-shrink: 0;
  border-radius: 4px;

  svg {
    width: 80px;
    height: 80px;
  }

  @media (max-width: 490px) {
    width: 100%;
    height: 200px;
  }
}

.link {
  text-decoration: underline;
  text-underline-offset: 5px;

  &:hover {
    cursor: pointer;
    color: var(--primary);
  }
}

h4 {
  font-size: 22px;
  margin-top: 0px;
  text-transform: uppercase;
}

.date {
  color: #606470;
  margin-left: auto;

  @media (max-width: 900px) {
    margin-top: 15px;
  }
}
</style>
