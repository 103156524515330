<template>
  <section class="flex center column container">
    <h1>U17</h1>
    <div class="player-grid">
      <div class="flex column player" v-for="(player, index) in players" :key="index">
        <img :src="imagesUrl + player.player.id + '/' + player.player.photo" />
        <div class="flex items-center">
          <h2>{{ player.jerseyNumber }}</h2>
          <div>
            <h4>{{ `${player.player.name} ${player.player.surname}` }}</h4>
            <h5>{{ player.position }}</h5>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { ExpandedPlayer } from '@/models/special.model';
import { BASE_URL } from '@/services/helpers';
import { getTeamById } from '@/services/teams_service';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'TeamDetailsView',
  data() {
    return {
      imagesUrl: `${BASE_URL}files/players/`,
      players: [] as Array<ExpandedPlayer>,
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await getTeamById(this.$route.params.id as string);

        this.players = response.items.map((item) => ({
          position: item.position,
          player: item.expand.player,
          jerseyNumber: item.jerseyNumber,
        }));
      } catch (error) {
        this.$router.push('/404');
      }
    },
  },
  mounted() {
    this.fetchData();
  },
});
</script>

<style scoped>
section {
  min-height: 60vh;
}

.container {
  padding: 60px 0px;
  gap: 20px;
}

h1 {
  font-size: 100px;
  font-weight: 1000;
}

.player-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 30px;
  row-gap: 50px;

  @media (max-width: 1250px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
  @media (max-width: 940px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 610px) {
    grid-template-columns: 1fr;
  }
}

.player {
  align-items: baseline;

  .flex {
    gap: 15px;
    margin-top: 10px;
  }

  h2 {
    font-size: 60px;
    line-height: 60px;
  }

  h4 {
    text-transform: uppercase;
    font-weight: normal;
    margin-bottom: 0;
    margin-top: 0;
  }

  h5 {
    text-transform: uppercase;
    font-weight: normal;
    color: #797979;
  }

  img {
    object-fit: cover;
    object-position: top;
    width: 270px;
    height: 350px;
  }
}
</style>
