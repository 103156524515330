<template>
  <button @click="$emit('clickEvent')">
    <span>{{ text }}</span>
  </button>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Button',
  props: {
    text: String,
    normalColor: {
      type: String,
      default: 'var(--primary)',
    },
    hoverColor: {
      type: String,
      default: 'var(--secondary)',
    },
  },
});
</script>

<style scoped>
button {
  padding: 10px 30px;
  font-size: 16px;
  transform: skew(-13deg);
  background-color: v-bind(normalColor);
  border: none;
  cursor: pointer;
  width: fit-content;

  background: linear-gradient(to left, v-bind(hoverColor) 50%, v-bind(normalColor) 50%);
  background-size: 205% 100%;
  background-position: left bottom;
  transition: all 0.35s ease-out;
}

button:hover {
  background-position: right bottom;
}

span {
  transform: skew(13deg);
  color: white;
  font-weight: 600;
}
</style>
