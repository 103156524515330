<template>
  <nav :class="{ transparent: isRoute('/') }" v-if="!$route.meta.hideNavbar">
    <img src="/img/zagi-logo.svg" alt="Logo" />
    <div class="header">
      <router-link to="/">
        <span>Početna</span>
      </router-link>
      <router-link to="/novosti">
        <span>Novosti</span>
      </router-link>
      <router-link to="/timovi">
        <span>Timovi</span>
      </router-link>
      <router-link to="/treneri">
        <span>Treneri</span>
      </router-link>
      <router-link to="/onama">
        <span>O nama</span>
      </router-link>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Header',
  methods: {
    isRoute(route: string) {
      return this.$route.path === route;
    },
  },
});
</script>

<style scoped>
nav {
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: var(--mirage);
}

.transparent {
  background-color: rgba(22, 28, 38, 0.6);
}

img {
  height: 130px;
  position: absolute;
  margin-right: 640px;
}

nav a {
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  height: 80px;
  transform: skew(-13deg, 0deg);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -2px;

  span {
    font-size: 15px !important;
    color: white;
  }
}

div.header {
  margin-left: 80px;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
}

span {
  transform: skew(13deg, 0deg);
  padding: 25px 20px;
}

nav a:hover,
nav a.router-link-active {
  background-color: #104d8c;
}
</style>
