<template>
  <section class="most-recent" v-if="news.length > 0">
    <h3>Najnovije</h3>
    <div class="news-cards flex column">
      <div
        class="news-card flex column"
        @click="goToNews(news.id)"
        v-for="(news, index) in news"
        :key="index"
      >
        <img v-if="news.lead_photo" :src="imagesUrl + news.id + '/' + news.lead_photo" />
        <div class="no-image flex column center" v-if="!news.lead_photo">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="lucide lucide-camera-off"
          >
            <line x1="2" x2="22" y1="2" y2="22" />
            <path d="M7 7H4a2 2 0 0 0-2 2v9a2 2 0 0 0 2 2h16" />
            <path d="M9.5 4h5L17 7h3a2 2 0 0 1 2 2v7.5" />
            <path d="M14.121 15.121A3 3 0 1 1 9.88 10.88" />
          </svg>
        </div>
        <p class="title">{{ news.title }}</p>
        <p class="date">{{ formatDate(news.date) }}</p>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { News } from '@/models/news.model';
import { BASE_URL, constructQuery, formatDate } from '@/services/helpers';
import { getNews } from '@/services/news_service';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MostRecent',
  data() {
    return {
      query: constructQuery(1, 3, '-date'),
      news: [] as Array<News>,
      imagesUrl: `${BASE_URL}files/news/`,
    };
  },
  methods: {
    async fetchData() {
      try {
        const response = await getNews(this.query);
        this.news = response.items;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    goToNews(id: string) {
      this.$router.push('/novosti/' + id);
    },
    formatDate,
  },
  mounted() {
    this.fetchData();
  },
});
</script>

<style scoped>
.most-recent {
  flex-shrink: 0;

  @media (max-width: 1200px) {
    margin-top: 30px;
  }

  @media (max-width: 700px) {
    width: 100%;
  }

  h3 {
    text-transform: uppercase;
    margin-bottom: 20px;
  }

  img {
    width: 330px;
    height: 200px;
    object-fit: cover;

    @media (max-width: 700px) {
      width: 100%;
    }
  }
}
.news-cards {
  gap: 30px;

  @media (max-width: 1335px) {
    flex-direction: row;
  }

  @media (max-width: 700px) {
    align-items: flex-start;
    margin-bottom: 50px;
  }
}

.news-card {
  width: 100%;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;

  @media (max-width: 1335px) {
    width: fit-content;
  }

  @media (max-width: 700px) {
    width: 100%;
  }

  p {
    margin: 0;
  }

  p.date {
    margin-top: -3px;
    font-size: 14px;
  }

  .no-image {
    background-color: #a3a3a3;
    width: 330px;
    height: 200px;
    flex-shrink: 0;
    border-radius: 4px;

    svg {
      width: 60px;
      height: 60px;
    }
  }

  img,
  .no-image {
    @media (max-width: 735px) {
      width: 100%;
      height: 300px;
    }

    @media (max-width: 500px) {
      height: 230px;
    }

    @media (max-width: 370px) {
      height: 200px;
    }
  }
}
</style>
