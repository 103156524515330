import { Coach } from '@/models/coach.model';
import axios from 'axios';
import { COLLECTIONS_URL, ApiResponse } from './helpers';

const COACHES_URL = `${COLLECTIONS_URL}/coaches/records`;

export async function getCoaches(query: string): Promise<ApiResponse<Coach>> {
  const response = await axios.get(`${COACHES_URL}/${query}`);
  return response.data;
}

export async function getCoachById(id: number): Promise<Coach> {
  const response = await axios.get(`${COACHES_URL}/${id}`);
  return response.data;
}

/* export async function createCoach(coach: Omit<Coach, "id">): Promise<Coach>  {
    const response = await axios.post(`${BASE_URL}/coaches`, coach);
    return response.data;
}

export async function updateCoach(id: number, coach: Coach): Promise<Coach>  {
    const response = await axios.put(`${BASE_URL}/coaches/${id}`, coach);
    return response.data;
}

export async function deleteCoach(id: number): Promise<void>  {
    await axios.delete(`${BASE_URL}/coaches/${id}`);
} */
