<template>
  <div class="gallery">
    <img :src="getImageUrl(images[0])" class="lead" v-on:click="show(0)" />
    <div class="more">
      <img v-if="images.length > 2" :src="getImageUrl(images[1])" v-on:click="show(1)" />
      <img v-if="images.length > 3" :src="getImageUrl(images[2])" v-on:click="show(2)" />
      <span class="more-link flex center" v-if="images.length > 3" v-on:click="show(0)"
        >+ {{ images.length - 3 }}</span
      >
    </div>
  </div>
  <dialog ref="galleryFullscreen">
    <Carousel
      class="images"
      :items-to-show="1"
      :wrapAround="true"
      :touch-drag="true"
      :transition="transition"
      ref="carousel"
    >
      <Slide v-for="(image, index) in images" :key="index">
        <img :src="getImageUrl(image)" />
      </Slide>
    </Carousel>

    <section class="controls flex space-btwn">
      <button type="button" class="flex" v-on:click="prev()">
        <img src="/img/svg/arrow-left.svg" />
      </button>
      <span>{{ index + 1 }} / {{ images.length }}</span>
      <button type="button" class="flex" v-on:click="next()">
        <img src="/img/svg/arrow-right.svg" />
      </button>
    </section>

    <button class="close" v-on:click="close()">
      <img src="/img/svg/close.svg" />
    </button>
  </dialog>
</template>

<script lang="ts">
import { BASE_URL } from '@/services/helpers';
import { defineComponent } from 'vue';
import { Carousel, Slide } from 'vue3-carousel';

export default defineComponent({
  name: 'Gallery',
  components: {
    Carousel,
    Slide,
  },
  props: {
    newsId: {
      type: String,
      required: true,
    },
    images: {
      type: Array<string>,
      required: true,
    },
  },
  data() {
    return {
      body: document.getElementsByTagName('html')[0],
      index: 0,
      transition: 300,
    };
  },
  methods: {
    getImageUrl(image: string) {
      return `${BASE_URL}files/news/` + this.newsId + '/' + image;
    },
    show(index?: number) {
      this.body.style.overflow = 'hidden';
      (this.$refs.galleryFullscreen as HTMLDialogElement).showModal();
      (this.$refs.carousel as any).updateSlideWidth();
      this.transition = 0;

      if (index != undefined && index != null) {
        (this.$refs.carousel as any).slideTo(index);
        this.index = index;
      }
    },
    close() {
      this.body.style.overflow = '';
      (this.$refs.galleryFullscreen as HTMLDialogElement).close();
    },
    prev() {
      if (this.transition == 0) this.transition = 300;

      (this.$refs.carousel as any).nav.prev();
      if (this.index == 0) this.index = this.images.length - 1;
      else this.index--;
    },
    next() {
      if (this.transition == 0) this.transition = 300;

      setTimeout(() => {
        (this.$refs.carousel as any).nav.next();
        if (this.index == this.images.length - 1) this.index = 0;
        else this.index++;
      }, 0);
    },
  },
});
</script>

<style scoped>
.gallery {
  margin: 3rem 0;
}
.lead {
  width: 776px;
  height: 450px;
  object-fit: cover;
  object-position: center;
  border-radius: 4px;
  cursor: pointer;

  @media (max-width: 960px) {
    width: calc(100vw - 10rem);
  }

  @media (max-width: 735px) {
    width: 100%;
    height: auto;
    aspect-ratio: 2 / 1.25;
  }
}

.images {
  width: calc(100% - 2rem);
  height: calc(100% - 6rem);
  margin: 3rem 2rem 3rem 0;
  outline: none;

  @media (max-width: 700px) {
    width: 100%;
    height: calc(100% - 2rem);
    margin: 1rem 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.more {
  width: 100%;
  max-width: 776px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 15px;
  gap: 25px;

  img {
    width: 100%;
    aspect-ratio: 2/1.2;
    object-fit: cover;
    object-position: center;
    cursor: pointer;
  }

  @media (max-width: 701px) {
    grid-template-columns: 1fr 0fr 1fr;
    gap: 0.375rem;
  }
}

.more-link {
  width: 100%;
  height: 100%;
  font-size: 35px;
  font-weight: bold;
  border-radius: 4px;
  background-color: #f1f5f9;
  transition: all 300ms ease-out;

  @media (prefers-color-scheme: dark) {
    background-color: var(--mirage);
  }

  &:hover {
    background-color: var(--primary);
    color: var(--white);
  }
  cursor: pointer;
}

.count {
  gap: 10px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  background-color: var(--secondary);
  padding: 5px 10px;
  border-radius: 4px;

  span {
    color: var(--white);
  }

  img {
    width: 18px;
    height: 18px;
    margin-bottom: 1px;
  }
}

dialog {
  height: 100%;
  width: 100%;
  background-color: transparent;
  border: none;
  outline: none;
  border-radius: 0;
  padding: 1rem 2rem;
  margin: 0;

  @media (max-width: 700px) {
    padding: 0.5rem 0;

    &[open] {
      max-width: none;
      max-height: none;
      display: flex;
      justify-content: center;
    }
  }
}

dialog::backdrop {
  background-color: rgba(0, 0, 0, 0.92);
}

button {
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 0.5rem;
  cursor: pointer;
  background-color: transparent;

  img {
    width: 24px;
    height: 24px;
  }
}

.close {
  position: fixed;
  top: 10px;
  right: 0;
  padding: 0.5rem 2rem;

  @media (max-width: 700px) {
    padding: 0.5rem 1rem;

    img {
      height: 36px;
      width: 36px;
    }
  }
}

.controls {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 0.5rem 2rem;

  @media (max-width: 700px) {
    bottom: 15px;
  }
}

.controls > span {
  color: var(--white);
}

.controls > button {
  background-color: var(--primary);

  &:hover {
    background-color: var(--secondary);
  }
}
</style>

<style>
dialog {
  .carousel__slide {
    background: none;
  }

  .carousel__track,
  .carousel__viewport {
    height: 100%;
    width: 100%;
  }
}
</style>
