export const BASE_URL = 'https://zagi-database.pockethost.io/api/';
export const COLLECTIONS_URL = `${BASE_URL}collections`;

export type ApiResponse<T> = {
  page: number;
  perPage: number;
  totalItems: number;
  totalPages: number;
  items: Array<T>;
};

export function constructQuery(page = 1, pageSize = 50, sort?: string, filter?: string) {
  let query = `?page=${page}&perPage=${pageSize}`;

  if (sort) {
    query += `&sort=${sort}`;
  }

  if (filter) {
    query += `&filter=${filter}`;
  }

  return query;
}

export function formatDate(date: string): string {
  const newDate = new Date(date);

  return new Intl.DateTimeFormat('hr-HR', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  }).format(newDate);
}
