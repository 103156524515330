import axios from 'axios';
import { News } from '@/models/news.model';
import { ApiResponse, COLLECTIONS_URL } from './helpers';

const NEWS_URL = `${COLLECTIONS_URL}/news/records`;

export async function getNews(query: string): Promise<ApiResponse<News>> {
  const response = await axios.get(`${NEWS_URL}/${query}`);
  return response.data;
}

export async function getNewsById(id: string): Promise<News> {
  const response = await axios.get(`${NEWS_URL}/${id}`);
  return response.data;
}

/* export async function createNotification
    (notification: Omit<Notification, "idNotification">): Promise<Notification>  
{
    const response = await axios.post(
        `${BASE_URL}/notifications`, 
        notification
    );
    return response.data;
}

export async function updateNotification
    (id: number, notification: Notification): Promise<Notification>  
{    
    const response = await axios.put(
        `${BASE_URL}/notifications/${id}`, 
        notification
    );
    return response.data;
}

export async function deleteNotification(id: number): Promise<void>  {
    await axios.delete(`${BASE_URL}/notifications/${id}`);
} */
