<template>
  <Header v-if="!isMobile"></Header>
  <MobileHeader v-else></MobileHeader>
  <router-view />
  <Footer></Footer>
</template>

<script lang="ts">
import '../public/css/dialog.css';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import MobileHeader from './components/MobileHeader.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'App',
  components: { Header, Footer, MobileHeader },
  data() {
    return {
      isMobile: false,
    };
  },
  methods: {
    checkViewportWidth() {
      this.isMobile = window.innerWidth <= 1000;
    },
  },
  mounted() {
    this.checkViewportWidth();
    window.addEventListener('resize', this.checkViewportWidth);
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.checkViewportWidth);
  },
});
</script>

<style>
#app {
  --primary: #104d8c;
  --secondary: #059669;
  --dark: #161c26;
  --white: #fafaff;
  --mirage: #17202e;
  --text: rgb(51, 51, 51);
}

body {
  margin: 0;
  background-color: var(--white);

  @media (prefers-color-scheme: dark) {
    background-color: #14171c;
  }
}

@font-face {
  font-family: Inter;
  src: url('/src/assets/fonts/Inter-VariableFont_slnt\,wght.ttf');
}

@font-face {
  font-family: Hanken;
  src: url('/src/assets/fonts/HankenGrotesk-VariableFont_wght.ttf');
}

* {
  font-family: 'Inter';
  font-size: 18px;
  letter-spacing: -0.03em;
  color: var(--mirage);

  @media (prefers-color-scheme: dark) {
    color: var(--white);
  }
}

img {
  border-radius: 4px;
}

h1,
h2,
h3 {
  font-family: 'Hanken';
}

h1 {
  margin: 0;
  font-size: 50px;
  text-transform: uppercase;
  font-weight: 900;
}

h2 {
  margin: 0;
  font-size: 38px;
}

h3 {
  margin: 0;
  font-size: 26px;
}

h4 {
  font-size: 18px;
}

h5 {
  margin: 0;
}

.flex {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.center {
  justify-content: center;
}

.space-btwn {
  justify-content: space-between;
}

.end {
  justify-content: flex-end;
}

.column {
  flex-direction: column;
}

.fadeInContainer {
  overflow: hidden;
}

@keyframes fadeInLeft {
  from {
    translate: -200%;
    opacity: 0;
  }

  to {
    translate: 0;
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    translate: 200%;
    opacity: 0;
  }

  to {
    translate: 0;
    opacity: 1;
  }
}

.fadeInLeft {
  translate: -150%;
  animation-fill-mode: forwards;
  animation-duration: 1500ms;
  animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
}

.fadeInLeft.active {
  animation-name: fadeInLeft;
}

.fadeInRight {
  translate: 150%;
  animation-fill-mode: forwards;
  animation-duration: 1500ms;
  animation-timing-function: cubic-bezier(0.33, 1, 0.68, 1);
}

.fadeInRight.active {
  animation-name: fadeInRight;
}
</style>
