import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29e85267"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "news-details flex column"
}
const _hoisted_2 = { class: "header flex" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "flex content space-btwn" }
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Gallery = _resolveComponent("Gallery")!
  const _component_MostRecent = _resolveComponent("MostRecent")!

  return (_ctx.newsDetails)
    ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("section", _hoisted_2, [
          _createElementVNode("img", {
            src: _ctx.imagesUrl + _ctx.newsDetails.id + '/' + _ctx.newsDetails.lead_photo
          }, null, 8, _hoisted_3),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("small", null, _toDisplayString(_ctx.formatDate(_ctx.newsDetails.date)), 1),
            _createElementVNode("h1", null, _toDisplayString(_ctx.newsDetails.title), 1)
          ])
        ]),
        _createElementVNode("section", _hoisted_5, [
          _createElementVNode("div", null, [
            _createElementVNode("div", {
              class: "news-paragraphs",
              innerHTML: _ctx.content[0]
            }, null, 8, _hoisted_6),
            (_ctx.newsDetails.photos && _ctx.newsDetails.photos.length > 0)
              ? (_openBlock(), _createBlock(_component_Gallery, {
                  key: 0,
                  images: _ctx.newsDetails.photos,
                  "news-id": _ctx.newsDetails.id
                }, null, 8, ["images", "news-id"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", {
              class: "news-paragraphs",
              innerHTML: _ctx.content[1]
            }, null, 8, _hoisted_7)
          ]),
          _createVNode(_component_MostRecent)
        ])
      ]))
    : _createCommentVNode("", true)
}