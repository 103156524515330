import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pagination" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.previousPage && _ctx.previousPage(...args))),
      disabled: _ctx.currentPage === 1
    }, "Prethodna", 8, _hoisted_2),
    _createElementVNode("span", null, _toDisplayString(_ctx.currentPage) + " / " + _toDisplayString(_ctx.totalPages), 1),
    _createElementVNode("button", {
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.nextPage && _ctx.nextPage(...args))),
      disabled: _ctx.currentPage === _ctx.totalPages
    }, "Sljedeća", 8, _hoisted_3)
  ]))
}