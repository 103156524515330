
import { defineComponent } from 'vue';

const __default__ = defineComponent({
  name: 'Button',
  props: {
    text: String,
    normalColor: {
      type: String,
      default: 'var(--primary)',
    },
    hoverColor: {
      type: String,
      default: 'var(--secondary)',
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "09aa37a7": (_ctx.normalColor),
  "38b6553c": (_ctx.hoverColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__