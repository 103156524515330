import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-df65d18a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "team grid" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Container = _resolveComponent("Container")!

  return (_openBlock(), _createBlock(_component_Container, {
    title: "TIMOVI",
    color: "var(--primary)",
    "text-color": "var(--white)",
    "border-color": "var(--secondary)",
    style: {"min-height":"60vh"}
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.teams, (team, index) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "flex column center",
            key: index,
            onClick: ($event: any) => (_ctx.navigateToTeam(team.id))
          }, [
            _createElementVNode("img", {
              src: _ctx.imagesUrl + team.id + '/' + team.photo
            }, null, 8, _hoisted_3),
            _createElementVNode("h4", null, _toDisplayString(team.name), 1)
          ], 8, _hoisted_2))
        }), 128))
      ])
    ]),
    _: 1
  }))
}