<template>
  <section class="news flex column center">
    <NewsCard
      v-for="(news, index) in news"
      :key="index"
      :id="news.id"
      :title="news.title"
      :summary="news.summary"
      :image="imagesUrl + news.id + '/' + news.lead_photo"
      :date="news.date"
      :link="'/novosti/' + news.id"
    >
    </NewsCard>
    <h1 v-if="news.length == 0">Nema novosti</h1>
    <Pagination
      v-if="news.length > 0"
      @pageChange="changePage"
      :current-page="currentPage"
      :total-pages="totalPages"
    >
    </Pagination>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import NewsCard from '@/components/NewsCard.vue';
import Pagination from '@/components/Pagination.vue';
import { News } from '@/models/news.model';
import { BASE_URL, constructQuery, formatDate } from '@/services/helpers';
import { getNews } from '@/services/news_service';

export default defineComponent({
  name: 'NewsView',
  components: {
    NewsCard,
    Pagination,
  },
  data() {
    return {
      currentPage: 1,
      totalPages: 10,
      query: constructQuery(1, 5, '-date'),
      news: [] as Array<News>,
      imagesUrl: `${BASE_URL}files/news/`,
    };
  },
  methods: {
    changePage(page: number) {
      this.$router.push('/novosti?page=' + page);
    },
    async fetchData() {
      try {
        this.query = constructQuery(this.currentPage, 5, '-date');
        const response = await getNews(this.query);
        this.totalPages = response.totalPages;
        this.news = response.items;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    formatDate,
  },
  mounted() {
    const page = this.$route.query.page;
    if (!page || !Number(page)) this.$router.push('/novosti?page=1');

    this.currentPage = Number(page) || 1;
    this.fetchData();
  },
  watch: {
    '$route.query.page'(newPage) {
      this.currentPage = Number(newPage) || 1;
      this.fetchData();
    },
  },
});
</script>

<style scoped>
.news {
  padding: 60px 0px;
  gap: 30px;
}

section {
  min-height: 60vh;
}

h1 {
  font-size: 3rem;
}
</style>
