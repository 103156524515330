<template>
  <nav v-if="!$route.meta.hideNavbar">
    <a href="/" class="logo">
      <img src="/img/zagi-logo.svg" alt="Logo" />
    </a>
    <img src="/img/svg/menu.svg" class="toggle" @click="toggleHeader()" />
  </nav>
  <div class="grid" :class="{ collapsed: isCollapsed }">
    <div class="menu">
      <router-link to="/">
        <span>Početna</span>
      </router-link>
      <router-link to="/novosti">
        <span>Novosti</span>
      </router-link>
      <router-link to="/timovi">
        <span>Timovi</span>
      </router-link>
      <router-link to="/treneri">
        <span>Treneri</span>
      </router-link>
      <router-link to="/onama">
        <span>O nama</span>
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MobileHeader',
  data() {
    return {
      isCollapsed: true,
    };
  },
  methods: {
    toggleHeader() {
      this.isCollapsed = !this.isCollapsed;
    },
  },
  watch: {
    $route() {
      this.isCollapsed = true;
    },
  },
});
</script>

<style scoped>
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  background-color: var(--mirage);
}

img {
  height: 30px;
}

:is(.logo) > img {
  height: 50px;
}

a:not(.logo) {
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  padding: 15px;
  font-size: 13px;
  background-color: var(--dark);
  border-top: 1px solid #2a2a2a;

  span {
    color: white;
  }
}

.grid {
  display: grid;
  grid-template-rows: 1fr;
  transition: all 0.5s ease;

  position: absolute;
  z-index: 999;
  width: 100vw;
}

.menu {
  overflow: hidden;
}

.collapsed {
  grid-template-rows: 0fr;
}

a.router-link-active {
  background-color: #104d8c;
}
</style>
