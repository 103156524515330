<template>
  <section class="flex center column" :class="color === 'var(--white)' ? 'white' : ''">
    <h3>{{ title }}</h3>
    <slot></slot>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Container',
  props: {
    title: String,
    color: {
      default: 'var(--white)',
      type: String,
    },
    textColor: {
      default: 'var(--text)',
      type: String,
    },
    borderColor: {
      default: 'var(--text)',
      type: String,
    },
  },
});
</script>

<style scoped>
section {
  background-color: v-bind('color');
  padding: 4em;
  gap: 20px;

  @media (max-width: 800px) {
    padding: 1.5em;
  }
}

section.white {
  @media (prefers-color-scheme: dark) {
    background-color: #14171c;

    h3 {
      color: var(--white);
    }
  }
}

h3 {
  color: v-bind('textColor');
  margin-bottom: 30px;
  border-bottom: 3px solid v-bind('borderColor');
  padding-bottom: 5px;
  width: fit-content;
  padding-left: 20px;
  padding-right: 20px;
  max-width: 350px;
  text-transform: uppercase;
  text-wrap: balance;
}
</style>
