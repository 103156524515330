
import { defineComponent } from 'vue';

const __default__ = defineComponent({
  name: 'Container',
  props: {
    title: String,
    color: {
      default: 'var(--white)',
      type: String,
    },
    textColor: {
      default: 'var(--text)',
      type: String,
    },
    borderColor: {
      default: 'var(--text)',
      type: String,
    },
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "3a9bbd3a": (_ctx.color),
  "5add2036": (_ctx.textColor),
  "3ca2f897": (_ctx.borderColor)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__