<template>
  <div class="not-found flex center column">
    <div className="flex column center">
      <h1 className="text-6xl">404</h1>
      <h2 className="text-2xl">Stranica nije pronađena</h2>
      <p className="mb-8">
        Ups! Stranica koju tražite ne postoji. Možda je premještena ili izbrisana.
      </p>
      <Button @clickEvent="navigateHome()" text="Povratak na naslovnicu"></Button>
    </div>
  </div>
</template>

<script lang="ts">
import Button from '@/components/Button.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'NotFoundView',
  components: {
    Button,
  },
  methods: {
    navigateHome() {
      this.$router.push('/');
    },
  },
});
</script>

<style scoped>
.not-found {
  height: 70dvh;
  padding: 2rem 16px 4rem;
}

.text-6xl {
  font-size: 4rem;
  line-height: 1;
  font-weight: bolder;
  margin-bottom: 1rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
  margin-bottom: 1rem;
  font-weight: normal;
}

.mb-8 {
  margin-bottom: 2rem;
  color: rgb(163 163 163);
  max-width: 400px;
  text-align: center;
}
</style>
