import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4bca7644"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "news flex column center" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NewsCard = _resolveComponent("NewsCard")!
  const _component_Pagination = _resolveComponent("Pagination")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.news, (news, index) => {
      return (_openBlock(), _createBlock(_component_NewsCard, {
        key: index,
        id: news.id,
        title: news.title,
        summary: news.summary,
        image: _ctx.imagesUrl + news.id + '/' + news.lead_photo,
        date: news.date,
        link: '/novosti/' + news.id
      }, null, 8, ["id", "title", "summary", "image", "date", "link"]))
    }), 128)),
    (_ctx.news.length == 0)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, "Nema novosti"))
      : _createCommentVNode("", true),
    (_ctx.news.length > 0)
      ? (_openBlock(), _createBlock(_component_Pagination, {
          key: 1,
          onPageChange: _ctx.changePage,
          "current-page": _ctx.currentPage,
          "total-pages": _ctx.totalPages
        }, null, 8, ["onPageChange", "current-page", "total-pages"]))
      : _createCommentVNode("", true)
  ]))
}