<template>
  <div class="flex center column">
    <div class="about">
      <h1>O NAMA</h1>
      <div class="content">
        <p>
          Futsal Zagi osnovana je 11.09.2016. godine u Zagrebu od strane bivših hrvatskih futsal internacionalaca i reprezentativaca Vedrana Matoševića i Željka Petrovića koji su odlučili znanje i kvalitete stećene tijekom karijere nastaviti prenositi u radu s djecom predškolskog i osnovnoškolskog uzrasta.
        </p>
        <p>
          Osnivači kluba su tijekom sportske karijere bilježili velike uspjehe, a među najveće bi svakako izdvojili:
        </p>
        <ul>
          <li>Nastup na UEFA Futsal EURO s Hrvatskom reprezentacijom (2012. i 2014. godine Vedran, 2016. godine Vedran i Željko, 2022. godine Vedran)</li>
          <li>Prvaci Hrvatske (MNK Alumnus 2014. godine Vedran, MNK Nacional 2015. godine Željko, MNK Nacional 2016. godine Vedran)</li>
          <li>Osvajači kupa Hrvatske (MNK Nacional 2015. godine Željko, MNK Nacional 2016. godine Vedran)</li>
          <li>Sveučilišni sportski uspjesi (Privaci Hrvatske 2012. i 2013. godine-Sveučilište VERN, Privaci Europe 2015. godine-Sveučilište VERN)</li>
        </ul>
        <p>
          Osim navedenog osnivači su i bivši stipendisti Sveučilišta VERN gdje su uz sportske uspjehe stekli i visoku školsku naobrazbu.
        </p>
        <p>Škola je djeluje na području Novog Zagreba te je u ovome trenutku brojit 90 stalnih polaznika koji su postigli zavidne ovim dječjim sportom.</p>
        <p>Od svog osnutka, škola je zabilježila puno sjajnih rezultata i osvojila pregršt medalja na različitim ligama i turnirima.</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
h1 {
  font-size: 30px;
  margin-bottom: 30px;
  width: fit-content;
  border-bottom: 2px solid var(--secondary);
}

.image {
  width: 100%;
  margin-top: 30px;
  margin-bottom: 30px;
}

.about {
  padding: 60px 30px;
  margin-bottom: auto;
}

.content {
  max-width: 800px;
  line-height: 22px;
  text-align: justify;
}

.flex {
  min-height: 60vh;
}

.underline {
  border-bottom: 1px solid var(--secondary);
}
</style>
