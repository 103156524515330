<template>
  <Banner></Banner>

  <Container title="O NAMA" color="var(--white)" border-color="var(--primary)">
    <div class="flex center about-container fadeInContainer">
      <img class="about fadeInLeft" src="img/about.jpg" alt="IMAGE" />
      <div class="description fadeInRight">
        <p>
          Futsal Zagi osnovana je 11.09.2016. godine u Zagrebu od strane bivših hrvatskih futsal internacionalaca i reprezentativaca Vedrana Matoševića i Željka Petrovića koji su odlučili znanje i kvalitete stećene tijekom karijere nastaviti prenositi u radu s djecom predškolskog i osnovnoškolskog uzrasta.
        </p>

        <Button
          style="margin-left: 10px"
          text="Saznajte više"
          @clickEvent="navigate('/onama')"
        ></Button>
      </div>
    </div>
  </Container>

  <Container
    class="fadeInContainer"
    title="TIMOVI"
    color="var(--primary)"
    text-color="var(--white)"
    border-color="var(--secondary)"
  >
    <div class="flex center fadeInLeft">
      <div class="team flex center">
        <div
          class="flex column center"
          v-for="(team, index) in teams"
          :key="index"
          @click="navigate(`/timovi/${team.id}`)"
        >
          <img :src="teamsImagesUrl + team.id + '/' + team.photo" />
          <h4>{{ team.name }}</h4>
        </div>
      </div>
    </div>
    <Button
      text="Vidi sve timove"
      normalColor="var(--secondary)"
      hoverColor="var(--primary)"
      @clickEvent="navigate('/timovi')"
    ></Button>
  </Container>

  <Container class="fadeInContainer" title="TRENERI" border-color="var(--primary)">
    <div class="flex center fadeInRight">
      <div class="flex center" style="gap: 25px">
        <div v-for="(coach, index) in coaches" :key="index" class="flex column coach">
          <img :src="coachesImagesUrl + coach.id + '/' + coach.photo" />
          <div>
            <h4>{{ `${coach.name} ${coach.surname}`.toUpperCase() }}</h4>
            <h5>{{ coach.role.toUpperCase() }}</h5>
          </div>
        </div>
      </div>
    </div>
    <Button
      style="margin-top: 35px"
      text="Vidi sve trenere"
      @clickEvent="navigate('/treneri')"
    ></Button>
  </Container>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Button from '@/components/Button.vue';
import Container from '@/components/Container.vue';
import Banner from '@/components/Banner.vue';
import { Team } from '@/models/team.model';
import { Coach } from '@/models/coach.model';
import { getTeams } from '@/services/teams_service';
import { BASE_URL, constructQuery } from '@/services/helpers';
import { getCoaches } from '@/services/coaches_service';

export default defineComponent({
  name: 'HomeView',
  components: {
    Button,
    Container,
    Banner,
  },
  data() {
    return {
      teamsQuery: constructQuery(1, 3, '-name'),
      coachesQuery: constructQuery(1, 3),
      teamsImagesUrl: `${BASE_URL}files/teams/`,
      coachesImagesUrl: `${BASE_URL}files/coaches/`,
      teams: [] as Array<Team>,
      coaches: [] as Array<Coach>,
    };
  },
  methods: {
    async fetchData() {
      try {
        const teamsResponse = await getTeams(this.teamsQuery);
        this.teams = teamsResponse.items;

        const coachesResponse = await getCoaches(this.coachesQuery);
        this.coaches = coachesResponse.items;
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    },
    startAnimation(entries: any) {
      entries.forEach((entry: any) => {
        if (entry.isIntersecting) {
          const children = entry.target.children;
          for (let index = 0; index < children.length; index++) {
            children[index].classList.add('active');
          }
        }
      });
    },
    navigate(page: string) {
      this.$router.push({ path: page });
    },
  },

  mounted() {
    this.fetchData();
    let options = {
      threshold: 0.1,
    };

    let observer = new IntersectionObserver(this.startAnimation, options);
    let fadeContainers = document.querySelectorAll('.fadeInContainer');
    fadeContainers.forEach((target) => observer.observe(target));
  },
});
</script>

<style scoped>
p {
  max-width: 550px;
  text-align: justify;
}

.about-container {
  gap: 50px;
  align-items: flex-start;

  @media (max-width: 1211px) {
    gap: 20px;
    flex-direction: column;
    align-items: baseline;
    justify-content: baseline;
  }
}

.description p:first-child {
  margin-top: 0;
}

.about {
  width: 600px;
  height: 370px;

  @media (max-width: 900px) {
    width: 100%;
    height: 300px;
  }

  @media (max-width: 490px) {
    width: 100%;
    height: 235px;
  }
}
.team {
  gap: 25px;
  cursor: pointer;

  h4 {
    color: white;
  }

  img {
    width: 370px;
    height: 250px;
    object-fit: cover;

    @media (max-width: 600px) {
      width: 100%;
      height: 200px;
    }
  }
}

.coach {
  align-items: baseline;

  h4 {
    font-weight: normal;
    margin-bottom: 5px;
  }

  h5 {
    font-weight: normal;
    color: #797979;
  }

  img {
    object-fit: cover;
    object-position: top;
    width: 270px;
    height: 350px;
  }
}
</style>
