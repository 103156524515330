import axios from 'axios';
import { ApiResponse, COLLECTIONS_URL } from './helpers';
import { Team } from '@/models/team.model';
import { PlaysForTeam } from '@/models/special.model';

const TEAMS_URL = `${COLLECTIONS_URL}/teams/records`;
const PLAYS_FOR_TEAM_URL = `${COLLECTIONS_URL}/plays_for_team/records`;

export async function getTeams(query: string): Promise<ApiResponse<Team>> {
  const response = await axios.get(`${TEAMS_URL}/${query}`);
  return response.data;
}

export async function getTeamById(id: string): Promise<ApiResponse<PlaysForTeam>> {
  const response = await axios.get(`${PLAYS_FOR_TEAM_URL}/?filter=(team='${id}')&expand=player`);
  return response.data;
}

/* export async function createTeam(team: Omit<Team, "idTeam">): Promise<Team>  {
    const response = await axios.post(`${BASE_URL}/teams`, team);
    return response.data;
}

export async function updateTeam(id: number, team: Team): Promise<Team>  {
    const response = await axios.put(`${BASE_URL}/teams/${id}`, team);
    return response.data;
}

export async function deleteTeam(id: number): Promise<void>  {
    await axios.delete(`${BASE_URL}/teams/${id}`);
} */
