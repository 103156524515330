<template>
  <div class="pagination">
    <button @click="previousPage" :disabled="currentPage === 1">Prethodna</button>
    <span>{{ currentPage }} / {{ totalPages }}</span>
    <button @click="nextPage" :disabled="currentPage === totalPages">Sljedeća</button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'Pagination',
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
  },
  methods: {
    previousPage() {
      this.$emit('pageChange', this.currentPage - 1);
    },
    nextPage() {
      this.$emit('pageChange', this.currentPage + 1);
    },
  },
});
</script>

<style>
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px;
}

.pagination button {
  margin: 0 5px;
  background: var(--primary);
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: var(--secondary);
  }
}
</style>
